import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Vuelidate from "vuelidate";

import mainUtils from "@/utils/main-utils";
import convertUtils from "@/utils/convert-utils";
import uploader from "@/utils/uploader.js";
import ImageComp from "@/components/cores/ImageComp";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import vuetify from "@/plugins/vuetify";
import VueDatePicker from "@/components/cores/datepicker.vue";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueSweetalert2);
Vue.use(PerfectScrollbar);
Vue.use(Vuelidate);

Vue.prototype.$utils = {
  main: { ...mainUtils },
  convert: { ...convertUtils },
  uploader: { ...uploader },
};

library.add(fas);
library.add(far);

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("image-comp", ImageComp);
Vue.component("vue-date-picker", VueDatePicker);
Vue.component("multi-select", {
  mixins: [Multiselect],
  props: {
    selectLabel: {
      default: "",
    },
    deselectLabel: {
      default: "X",
    },
    selectedLabel: {
      default: "",
    },
    showLabels: {
      default: true,
    },
    closeOnSelect: {
      default: true,
    },
    searchable: {
      default: true,
    },
    placeholder: {
      default: "",
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  filters: { ...convertUtils },
  methods: {
    alertSuccess(title, text, timer = 2500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertFail(title, text, timer = 2500) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ทำรายการไม่สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "error",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertSuccessConfirm(title, text, timer = 2000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            //timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertConfirm(title, text, showCancel = true, timer = 2000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: showCancel,
            showConfirmButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            customClass: {
              confirmButton: "btn btn-warning mx-2",
              cancelButton: "btn btn-secondary mx-2",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "question",
            reverseButtons: true,
            //timer: timer,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertLoading(title, show = false) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">กำลังดาวน์โหลด</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="d-flex justify-content-center mb-3 mt-3">
              <div class="loader"></div>
            </div>
            <div class="d-col">
              ${_title.join("")}
            </div>
            `,
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            allowOutsideClick: true,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertWithInput() {
      // submit delete should be override in component
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            <div class="fontsize-md">กรอกรหัสผ่าน CA</div>
            </div>
            <div>
            <input id="swal-input1" class="form-control mt-3" type="text"
            placeholder="รหัสผ่าน CA">
            </div>`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            buttonsStyling: false,
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn btn-warning mx-2",
              cancelButton: "btn btn-secondary mx-2",
            },
            reverseButtons: true,
            preConfirm: () => {
              return {
                password: document.getElementById("swal-input1").value,
              };
            },
          })
          .then((value) => {
            resolve(value);
          });
      });
    },
  },
});
