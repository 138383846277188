<template>
  <div id="AdminFooter" class="text-center admin-footer">
    Copyright By Piesoft Co.,Ltd. | Version {{ appVersion }}
  </div>
</template>

<script>
export default {
  name: "admin-footer",
  data() {
    return {
      appVersion: null,
    };
  },
  created() {
    this.appVersion = process.env.VUE_APP_BASE_WEB_VERSION_SHOW;
  },
};
</script>

<style lang="scss">
.admin-footer {
  padding-left: $sidebar-width;
  height: $footer-height;

  padding-top: 20px;
  padding-bottom: 5px;
}
</style>
