// import { ImageAttachment, AttachmentBase } from "@/classes/Attachment"

const attachmentList = {
  id: 0,
  type: "",
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  img: null,
  file: null,
};

const attachmentPdf = {
  pageNumber: 0,
  attachments: [], // attachmentList
};

const state = {
  // pageCurrent: -1,
  // pageAttachments: [], //attachmentList
  allPageAttachments: [], // attachmentPdf
};

const mutations = {
  ADD_ATTACHMENT(state, data) {
    console.log("ADD_ATTACHMENT", data);

    const pageNumber = data.pageNumber;
    const attachment = data.attachment;

    const isFind =
      state.allPageAttachments.filter((f) => {
        // if(f.pageNumber == pageNumber){
        //  return f
        // }
        return f.pageNumber == pageNumber;
      }).length != 0;

    if (isFind) {
      //push
      state.allPageAttachments.map((m) => {
        if (m.pageNumber == pageNumber) {
          return m.attachments.push(attachment);
        }
      });
    } else {
      //add new

      state.allPageAttachments.push({
        pageNumber: pageNumber,
        attachments: [{ ...attachment }],
      });
    }
  },
  UPDATE_ATTACHMENT(state, data) {
    console.log("UPDATE_ATTACHMENT", data);

    const pageNumber = data.pageNumber;
    const attachment = data.attachment;

    state.allPageAttachments = state.allPageAttachments.map((m) => {
      if (m.pageNumber == pageNumber) {
        m.attachments = m.attachments.map((m2) => {
          if (m2.id == attachment.id) {
            return { ...attachment };
          } else {
            return { ...m2 };
          }
        });
      }

      return { ...m };
    });

    console.log("state.allPageAttachments", state.allPageAttachments);
  },
  DELETE_ATTACHMENT(state, data) {
    const { pageNumber, id } = { ...data };
    state.allPageAttachments.map((m) => {
      if (m.pageNumber == pageNumber) {
        const inxDelete = m.attachments.findIndex((object) => {
          return object.id == id;
        });

        if (inxDelete >= 0) {
          m.attachments.splice(inxDelete, 1);
        }
      }
      return m;
    });
  },
  CLEAR_ATTACHMENT(state) {
    state.allPageAttachments = [];
  },
};

const getters = {
  pageAttachments: (state) => (pageNumber) => {
    const result =
      state.allPageAttachments.filter((f) => {
        return f.pageNumber == pageNumber;
      }) || [];

    console.log("pageAttachments", pageNumber, result);

    if (result.length != 0) {
      return result[0].attachments || [];
    }

    return [];
  },
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  // actions
};
