const menuList = [
  {
    menuId: 1,
    parentId: 0,
    title: "ลงลายมือชื่ออิเล็กทรอนิกส์",
    titleCollapse: "ลงลายมือ",
    iconClass: ["fa-solid", "fa-file-signature"],
    auth: ["auth_sign"],
    to: "Signature",
  },
  {
    menuId: 2,
    parentId: 0,
    title: "ประวัติเอกสารการลงลายมือชื่ออิเล็กทรอนิกส์",
    titleCollapse: "ประวัติเอกสารการลงลายมือ",
    iconClass: ["fa-solid", "fa-file-waveform"],
    auth: ["auth_hissign"],
    to: "HistorySignature",
  },
  {
    menuId: 3,
    parentId: 0,
    title: "จัดการรูปลายมือชื่อ",
    titleCollapse: "รูปลายมือชื่อ",
    iconClass: ["fa-solid", "fa-signature"],
    auth: ["auth_managesign"],
    to: "ManageSign",
  },
  {
    menuId: 4,
    parentId: 0,
    title: "ข้อมูลผู้ใช้งานระบบ",
    titleCollapse: "ข้อมูลผู้ใช้งานระบบ",
    iconClass: ["fas", "user"],
    auth: ["auth_system"],
    hasSub: true,
  },
  {
    menuId: 5,
    parentId: 4,
    title: "ผู้ใช้งาน",
    titleCollapse: "ผู้ใช้งาน",
    auth: ["auth_system"],
    to: "UserList",
  },
  {
    menuId: 6,
    parentId: 4,
    title: "ประวัติการใช้งานระบบ",
    titleCollapse: "ประวัติ",
    auth: ["auth_system"],
    to: "HistoryUse",
  },
];

const state = {
  menus: [...menuList],
};

export const menus = {
  namespaced: true,
  state,
};
