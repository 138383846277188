import Vue from "vue";
import VueRouter from "vue-router";
import AppLogin from "@/components/layouts/login/LoginLayout";
import AppLayout from "@/components/layouts/app/AppLayout";
import store from "@/store/index";
import { TokenService } from "@/services/storage.service";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
};

const routes = [
  {
    path: "",
    redirect: { name: "Login" },
  },
  {
    path: "*",
    redirect: { name: "Login" },
  },
  {
    path: "/",
    component: AppLogin,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("../views/Auth/Login.vue"),
      },
    ],
  },
  {
    path: "/",
    component: AppLayout,
    meta: {
      admin: true,
    },
    children: [
      {
        name: "Signature",
        path: "Signature",
        component: () => import("../views/Signature/Form.vue"),
      },
      {
        name: "HistorySignature",
        path: "HistorySignature",
        component: () => import("../views/HistorySignature/List.vue"),
      },
      {
        meta: {
          rootMenu: "UserList",
        },
        name: "UserForm",
        path: "User/:mode",
        component: () => import("../views/User/Form.vue"),
      },
      {
        name: "UserList",
        path: "User",
        component: () => import("../views/User/List.vue"),
      },
      {
        name: "HistoryUse",
        path: "HistoryUse",
        component: () => import("../views/HistoryUse/List.vue"),
      },
      {
        name: "ManageSign",
        path: "ManageSign",
        component: () => import("../views/ManageSign/Form.vue"),
      },
      {
        name: "ExamplePdf",
        path: "ExamplePdf",
        component: () => import("../views/Signature/Index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();

  if (to.matched.some((record) => record.meta.admin) && !haveToken) {
    changeNext = { name: "Login" };
  } else if (
    (to.matched.some((record) => record.meta.login) ||
      to.matched.some((record) => record.meta.public)) &&
    !haveToken
  ) {
  } else if (to.matched.some((record) => record.meta.login) && haveToken) {
    if (changeNext) {
      if (from.name === changeNext) {
        // hide loading page if from and next is same route
        store.commit("setPageLoading", false);
      }
      changeNext = { name: changeNext };
    } else {
      changeNext = { name: "Signature" };
    }
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
